import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { dispatchCreateNotification } from 'src/action-creators'
import { useCurrentBuyerCompany } from 'src/components/presentationals/buyer-dashboard/shared/hooks'
import { useCurrentMarketplace } from 'src/contexts/userContext'
import {
  BUYER_COMPANIES_QUERY_KEY,
  ORDERS_QUERY_KEY,
  PRODUCTS_QUERY_KEY,
  useOrderQuery,
  useProductQuery,
} from 'src/queries/buyer'
import { MARKETPLACES_QUERY_KEY } from 'src/queries/shared'
import { deletePaginatedEntity, updatePaginatedEntity } from 'src/queries/utils'
import { BuyerCompany, BuyerFilter, BuyerOrder, BuyerProduct, Marketplace } from 'src/types'
import getPusher, {
  DataFromActiveQuery,
  ORDER_UPDATED,
  PRODUCT_DELETED,
  PRODUCT_UPDATED,
  PUSHER_USER_REDIRECT,
  PusherPrivateChannelType,
  PusherUnsubscribeFunction,
  REACT_BUYER_COMPANY_UPDATED,
  REACT_FIXED_SHIPPING_DATE_QUANTITY_UPDATED,
  REACT_MARKETPLACE_UPDATED,
  REACT_STOCK_BULK_UPDATE_NEW_DAY,
  REACT_STOCK_UPDATED_BUYER,
  getDataFromActiveQuery,
} from 'src/utils/pusher'

import useDefaultMarketplaceId from '../marketplaces/useDefaultMarketplaceId'

// TODO: Move pusher events linked to the spot on it's own hook at BuyerLive level
const usePusherSubscriptions = () => {
  const dispatch = useDispatch()
  const { push, go } = useHistory()
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const timerRef: React.MutableRefObject<NodeJS.Timeout | null> = useRef(null)
  const pusherPrivateChannel = getPusher().privateChannel
  const pusherPublicChannel = getPusher().publicChannel
  const marketplace = useCurrentMarketplace()
  const defaultMarketplaceId = useDefaultMarketplaceId()

  const isLive = pathname.split('/').some(segment => segment === 'live')

  const currentBuyerCompany = useCurrentBuyerCompany()

  const [orderDataToUpdate, setOrderDataToUpdate] =
    useState<DataFromActiveQuery<BuyerOrder> | null>(null)
  const [productDataToUpdate, setProductDataToUpdate] =
    useState<DataFromActiveQuery<BuyerProduct> | null>(null)

  useOrderQuery({
    orderId: orderDataToUpdate?.entity?.id,
    queryOptions: {
      onSuccess: order => {
        if (!!orderDataToUpdate) {
          const updatedOrders = updatePaginatedEntity({
            entity: order,
            entityPages: orderDataToUpdate.paginatedData,
          })

          if (!!updatedOrders) queryClient.setQueryData(orderDataToUpdate.queryKey, updatedOrders)
        }
      },
      onSettled: () => {
        setOrderDataToUpdate(null)
      },
      retry: false,
      staleTime: 0,
    },
  })

  const queryParams: BuyerFilter = {
    buyerCompanyUuid: currentBuyerCompany?.uuid ? [currentBuyerCompany.uuid] : undefined,
    showOutOfStock: true,
  }

  useProductQuery({
    marketplaceId: marketplace?.id ?? defaultMarketplaceId,
    productId: productDataToUpdate?.entity?.id,
    queryParams,
    queryOptions: {
      onSuccess: product => {
        if (productDataToUpdate) {
          const updatedProducts = updatePaginatedEntity({
            entity: product,
            entityPages: productDataToUpdate.paginatedData,
          })

          if (updatedProducts)
            queryClient.setQueryData(productDataToUpdate.queryKey, updatedProducts)
        }
      },
      onSettled: () => {
        setProductDataToUpdate(null)
      },
      retry: false,
      staleTime: 0,
    },
  })

  const replaceHistory = useCallback(
    (url: string) => {
      push(url)
      go(0)
    },
    [push, go]
  )

  const handlePusherGetUpdatedProduct = useCallback(
    ({ spiId }: { spiId: Id }) => {
      const result = getDataFromActiveQuery<BuyerProduct>({
        id: spiId,
        queryClient,
        queryKey: PRODUCTS_QUERY_KEY,
      })

      if (!!result?.entity) setProductDataToUpdate(result)
    },
    [queryClient]
  )

  const handlePusherOrderUpdated = useCallback(
    ({ id }: { id: Id }) => {
      const result = getDataFromActiveQuery<BuyerOrder>({
        id,
        queryClient,
        queryKey: ORDERS_QUERY_KEY,
      })

      if (!!result?.entity) setOrderDataToUpdate(result)
    },
    [queryClient]
  )

  const handlePusherBuyerCompanyUpdated = useCallback(
    (updatedBuyerCompany: BuyerCompany) => {
      queryClient.setQueryData(
        [BUYER_COMPANIES_QUERY_KEY, updatedBuyerCompany.id],
        updatedBuyerCompany
      )

      const result = getDataFromActiveQuery<BuyerCompany>({
        queryClient,
        queryKey: BUYER_COMPANIES_QUERY_KEY,
      })

      if (!!result) {
        const updatedBuyerCompanies = updatePaginatedEntity({
          entity: updatedBuyerCompany,
          entityPages: result.paginatedData,
        })

        if (!!updatedBuyerCompanies)
          queryClient.setQueryData(result.queryKey, updatedBuyerCompanies)
      }
    },
    [queryClient]
  )

  const handlePusherMarketplaceUpdated = useCallback(
    (updatedMarketplace: Marketplace) => {
      const result = getDataFromActiveQuery<Marketplace>({
        queryClient,
        queryKey: MARKETPLACES_QUERY_KEY,
      })

      if (!!result) {
        const updatedMarketplaces = updatePaginatedEntity({
          entity: updatedMarketplace,
          entityPages: result.paginatedData,
        })

        if (!!updatedMarketplaces) queryClient.setQueryData(result.queryKey, updatedMarketplaces)
      }
    },
    [queryClient]
  )

  const handlePusherBulkStockUpdate = useCallback(() => {
    if (isLive) {
      dispatchCreateNotification(dispatch)({
        message: gettext(
          "Stock has been updated, now you're window will be refreshed in few seconds to display the new stock"
        ),
        type: 'informative',
      })

      timerRef.current = setTimeout(() => go(0), 10000)
    }
  }, [timerRef.current, isLive])

  const handlePusherProductDeleted = useCallback(
    ({ id }: { id: Id }) => {
      setProductDataToUpdate(null)

      const result = getDataFromActiveQuery<BuyerCompany>({
        queryClient,
        queryKey: PRODUCTS_QUERY_KEY,
      })

      if (!!result) {
        const updatedProducts = deletePaginatedEntity({
          entityId: id,
          entityPages: result.paginatedData,
        })

        if (!!updatedProducts) queryClient.setQueryData(result.queryKey, updatedProducts)
      }
    },
    [queryClient]
  )

  useEffect(
    () => () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    },
    []
  )

  useEffect(() => {
    const unSubscribePublicChannel = pusherPublicChannel.subscribe({
      [PRODUCT_DELETED]: handlePusherProductDeleted,
      [PRODUCT_UPDATED]: ({ id }: { id: Id }) => handlePusherGetUpdatedProduct({ spiId: id }),
      [REACT_STOCK_BULK_UPDATE_NEW_DAY]: handlePusherBulkStockUpdate,
      [REACT_STOCK_UPDATED_BUYER]: handlePusherGetUpdatedProduct,
      [REACT_FIXED_SHIPPING_DATE_QUANTITY_UPDATED]: handlePusherGetUpdatedProduct,
    })

    return () => unSubscribePublicChannel?.()
  }, [handlePusherGetUpdatedProduct, handlePusherBulkStockUpdate, handlePusherProductDeleted])

  useEffect(() => {
    let unSubscribePrivateCompanyChannel: PusherUnsubscribeFunction | undefined

    if (!!currentBuyerCompany?.uuid) {
      unSubscribePrivateCompanyChannel = pusherPrivateChannel(
        currentBuyerCompany.uuid,
        PusherPrivateChannelType.BUYER
      ).subscribe({
        [REACT_BUYER_COMPANY_UPDATED]: handlePusherBuyerCompanyUpdated,
        [ORDER_UPDATED]: handlePusherOrderUpdated,
        [PUSHER_USER_REDIRECT]: ({ redirect_url: redirectUrl }: { redirect_url: string }) =>
          replaceHistory(redirectUrl),
      })
    }

    return () => unSubscribePrivateCompanyChannel?.()
  }, [handlePusherOrderUpdated, currentBuyerCompany?.uuid, handlePusherBuyerCompanyUpdated])

  useEffect(() => {
    let unSubscribePrivateMarketplaceChannel: PusherUnsubscribeFunction | undefined

    if (!!marketplace?.uuid) {
      unSubscribePrivateMarketplaceChannel = pusherPrivateChannel(
        marketplace?.uuid,
        PusherPrivateChannelType.MARKETPLACE
      ).subscribe({
        [REACT_MARKETPLACE_UPDATED]: handlePusherMarketplaceUpdated,
      })
    }

    return () => unSubscribePrivateMarketplaceChannel?.()
  }, [marketplace?.uuid, handlePusherMarketplaceUpdated])
}

export default usePusherSubscriptions
